import React from "react"
import { graphql } from "gatsby"
import BasicCompatrix from "../components/basicCompatrix"

export default function Template({ data }) {
  const {
    ruleset,
    target,
    dependers,
    cheats,
    highlights,
  } = data.compatrixBasicJson
  const depender = dependers[0]

  return (
    <BasicCompatrix
      ruleset={ruleset}
      target={target}
      depender={depender}
      cheats={cheats}
      highlights={highlights}
    />
  )
}

export const compatrixQuery = graphql`
  query($id: String!) {
    compatrixBasicJson(id: { eq: $id }) {
      ruleset
      target {
        name
        versions
      }
      dependers {
        name
        versions {
          version
          inputs {
            use
            min
            incompats
            pincompats
            scouted
          }
        }
      }
    }
  }
`
